import React from "react";
import PropTypes from "prop-types";
import {
  Typography,
  Button,
  Card,
  CardContent,
  CardActionArea,
  CardMedia,
  CardActions
} from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import Img from "gatsby-image";
import Link from "./Link";
import Chip from "@material-ui/core/Chip";
import Truncate from "react-truncate";

const useStyles = makeStyles(theme => ({
  root: {
    minHeight: 400,
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "start",
    borderRadius: theme.borderRadius,
    boxShadow: "none",
    border: `1px solid #ddd`
  },
  flex: {
    flex: 1,
    padding: 10
  },
  btn: {
    borderRadius: theme.borderRadius,
    padding: theme.spacing(1, 2),
    margin: theme.spacing(1)
  },
  chip: {
    margin: theme.spacing(1),
    marginLeft: 0
  },
  titleAndChips: {
    display: "flex",
    alignItems: "center"
  },
  content: {
    paddingBottom: "0px !important"
  },
  chips: {
    minWidth: 100,
    overflowX: "auto",
    display: "flex",
    flexWrap: "nowrap"
  },
  cardMedia: {
    borderRadius: theme.borderRadius
  },
  desc: {
    marginTop: theme.spacing(1)
  },
  cardActions: {
    paddingLeft: 8,
    paddingTop: 0
  }
}));

export default function Project({
  title,
  thumbnail,
  description,
  slug,
  techs,
  special
}) {
  const classes = useStyles();
  return (
    <Card className={classes.root}>
      <CardActionArea className={classes.flex}>
        <Link to={slug}>
          <CardMedia
            component={Img}
            className={classes.cardMedia}
            style={{
              height: special ? "auto" : 293
            }}
            alt={title}
            fluid={thumbnail}
          />
          <CardContent className={classes.content}>
            <div className={classes.titleAndChips}>
              <Typography
                variant="h5"
                component="h2"
                style={{
                  marginRight: 10,
                  whiteSpace: "nowrap",
                  fontWeight: 700
                }}
              >
                {title}
              </Typography>
              <div className={classes.chips}>
                {techs.map((tech, index) => (
                  <Chip
                    className={classes.chip}
                    label={tech.name}
                    key={index}
                  />
                ))}
              </div>
            </div>
            <Typography
              className={classes.desc}
              variant="body1"
              component="p"
              style={{
                lineHeight: 1.75,
                color: "black"
              }}
            >
              {special ? (
                <Truncate lines={3} width={1150}>
                  {description}
                </Truncate>
              ) : (
                <Truncate lines={3} width={500}>
                  {description}
                </Truncate>
              )}
            </Typography>
          </CardContent>
        </Link>
      </CardActionArea>
      <CardActions className={classes.cardActions}>
        <Button
          size="small"
          color="primary"
          className={classes.btn}
          component={Link}
          to={slug}
        >
          Read More
        </Button>
      </CardActions>
    </Card>
  );
}

Project.propTypes = {
  thumbnail: PropTypes.object.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  slug: PropTypes.string.isRequired,
  techs: PropTypes.array.isRequired,
  special: PropTypes.bool
};

import React from "react";
import Project from "./Project";
import { useStaticQuery, graphql } from "gatsby";
import { Grid } from "@material-ui/core";
import Fade from "react-reveal/Fade";
import Bounce from "react-reveal/Bounce";

export default function Projects() {
  const data = useStaticQuery(graphql`
    query {
      contentfulProjectList(name: { eq: "ShowcasedProjects" }) {
        projects {
          id
          title
          slug
          summary
          demoLink
          sourceLink
          special
          techs {
            name
          }
          thumbnail {
            fluid(maxWidth: 1216) {
              base64
              aspectRatio
              src
              srcSet
            }
          }
        }
      }
    }
  `);
  let specialProject;
  let projects = [];

  data.contentfulProjectList.projects.forEach((project, index) => {
    if (project.special) {
      specialProject = (
        <Grid item xs={12}>
          <Fade up>
            <Project
              title={project.title}
              description={project.summary}
              thumbnail={project.thumbnail.fluid}
              slug={`/projects/${project.slug}`}
              techs={project.techs}
              special
            />
          </Fade>
        </Grid>
      );
    } else {
      projects.push(
        <Grid item lg={6} xs={12} key={project.id}>
          <Bounce left delay={index * 100} duration={1000}>
            <Project
              title={project.title}
              description={project.summary}
              thumbnail={project.thumbnail.fluid}
              slug={`/projects/${project.slug}`}
              techs={project.techs}
            />
          </Bounce>
        </Grid>
      );
    }
  });

  return (
    <Grid
      container
      spacing={4}
      style={{
        width: "100%",
        margin: "0 auto"
      }}
    >
      {specialProject ? specialProject : ""}
      {projects}
    </Grid>
  );
}

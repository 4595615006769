import React from "react";

import { makeStyles } from "@material-ui/styles";
import Layout from "../components/Layout";
import SEO from "../components/Seo";
import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Fab from "@material-ui/core/Fab";
import SendIcon from "@material-ui/icons/SendRounded";
import Projects from "../components/Projects";
import Link from "../components/Link";
import waves from "../imgs/waves_home.svg";
import laptop from "../imgs/laptop.svg";
import ArrowRightAltOutlinedIcon from "@material-ui/icons/ArrowForwardOutlined";
import Button from "@material-ui/core/Button";
import Bounce from "react-reveal/Bounce";
import "./index.css";

const useStyles = makeStyles(theme => ({
  splash: {
    backgroundColor: theme.palette.primary.dark,
    color: "white",
    minHeight: "calc(50vh - 50px)",
    display: "flex",
    paddingTop: theme.spacing(3),

    justifyContent: "center",
    alignItems: "center"
  },
  contactBtn: {
    zIndex: 1,
    marginTop: theme.spacing(3),
    backgroundImage: theme.primaryGradient.main
  },
  icon: {
    marginLeft: theme.spacing(2)
  },
  img: {
    width: 1000,
    zIndex: "50",
    position: "relative",
    //maxWidth: "100%",
    display: "none",
    [theme.breakpoints.up("md")]: {
      display: "block"
    }
  },
  bottom: {
    filter: "drop-shadow(0 10px 5px rgba(0,0,0,0.2))",
    width: "100%",
    height: 200,
    [theme.breakpoints.up("sm")]: {
      marginBottom: theme.spacing(-12)
    },
    marginBottom: theme.spacing(-6)
  },
  viewAll: {
    fontWeight: "bold",
    margin: 15,
    marginTop: 40,
    width: "calc(100% - 30px)",
    height: 50,
    borderRadius: theme.borderRadius,
    border: `1px solid #ddd`,
    "&:hover": {
      backgroundColor: theme.palette.primary.main,
      color: "white",
      border: "none" // useful for when clicking is active
    }
  }
}));
const IndexPage = () => {
  const classes = useStyles();
  return (
    <Layout>
      <SEO title="Home" />
      <div className={classes.splash}>
        <Container maxWidth="lg">
          <Grid
            container
            spacing={4}
            alignItems="center"
            justify="center"
            wrap="wrap-reverse"
          >
            <Grid item md={6}>
              <Typography variant="h1">I build web applications.</Typography>
              <Typography
                variant="h5"
                component="p"
                style={{
                  marginTop: 25,
                  marginBottom: 20,
                  lineHeight: 1.7
                }}
              >
                Hi, I'm Alexander Cardosi. I write web applications in React,
                Next.js, MUI, and more!
              </Typography>

              <Fab
                variant="extended"
                className={classes.contactBtn}
                color="inherit"
                component={Link}
                to="/contact"
              >
                Contact Me
                <SendIcon className={classes.icon} />
              </Fab>
            </Grid>

            <Grid item md={6}>
              <img className={classes.img} src={laptop} alt="Laptop" />
            </Grid>
          </Grid>
        </Container>
      </div>
      <img className={classes.bottom} alt="" src={waves} />
      <Container
        maxWidth="lg"
        style={{
          marginTop: 50 + 100,
          paddingRight: 0,
          paddingLeft: 0
        }}
      >
        <Typography
          variant="h1"
          color="primary"
          align="center"
          gutterBottom
          id="projects"
        >
          My Projects
        </Typography>

        <Projects />
        <Bounce left delay={300} duration={1000}>
          <Button
            variant="outlined"
            color={"primary"}
            endIcon={<ArrowRightAltOutlinedIcon />}
            className={classes.viewAll}
            size={"large"}
            component={Link}
            to={"/projects"}
          >
            View all projects
          </Button>
        </Bounce>
      </Container>
    </Layout>
  );
};

export default IndexPage;
